import React, { useState, useCallback, useEffect, useLayoutEffect, useContext, useRef } from 'react';

import { faAngleDown, faPhone, faExternalLink } from '@fortawesome/free-solid-svg-icons';

import { default as SA } from '../Activity/styled';
import S from './styled';
import SD from './drawerStyles';

import Button, { IconButton } from '../Button';
import AwesomeButton from '../AwesomeButton/AwesomeButton';

import MinddLogoLoader from '../MinddLogoLoader';
import EndButtons from '../EndButtons/EndButtons';
import ProcedurePostcodeRequest from '../ProcedurePostcodeRequest';
import SurveyIntro from '../SurveyIntro';

import { useStateContext } from '../../helpers/hooks/useStateContext';
import { useNextStepCallbackRequest } from '../../helpers/hooks/nextStep/useNextStepCallbackRequest';
import { getLabel } from '../../helpers/constants/getLabels';
import { renderMarkdown } from '../../helpers/support/renderMarkdown';
import { CallPostCallButtonClicked, CallPostSessionLogProperties } from '../../helpers/services';
import { advicePdfEventlistenerHelper } from '../../helpers/support/advicePdfEventlistenerHelper';
import { useToggleModal } from '../../helpers/hooks/useToggleModal';

import DownloadButton from '../DownloadButton/DownloadButton';
import WidgetDrawer from '../WidgetDrawer/WidgetDrawer';
import { useWindowSize } from '../../helpers/hooks/useWindowSize';
import { ThemeContext } from 'styled-components';
import ContactRequestContainer from './ContactRequestContainer';
import PhoneNumberList from './PhoneNumberList';
import ProcedureSendEmailOrSmsAdvice from '../ProcedureSendEmailOrSmsAdvice';
import { faBell } from '@fortawesome/free-regular-svg-icons';
import { default as SAT } from '../Activity/Activity.styled';

const useExpandable = () => {
	const [expanded, setExpanded] = useState(false);

	const toggleExpanded = () => {
		setExpanded(!expanded);
	};

	return [expanded, toggleExpanded];
};

const ExpandableDiv = ({ children }) => {
	const [expanded, toggleExpanded] = useExpandable();

	return (
		<S.ExpandableTextBox expanded={expanded}>
			<S.ExpandableTextBoxButton onClick={toggleExpanded} className="expandableTextButton">
				{expanded ? (
					<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-up" viewBox="0 0 16 16">
						<path fillRule="evenodd" d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708l6-6z" />
					</svg>
				) : (
					<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-down" viewBox="0 0 16 16">
						<path
							fillRule="evenodd"
							d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
						/>
					</svg>
				)}
			</S.ExpandableTextBoxButton>
			{children}
		</S.ExpandableTextBox>
	);
};

const ActivityAdvice = (props) => {
	const [{ conversation, settings, session, refs }, dispatch] = useStateContext();

	const handleNextStepCallbackRequest = useNextStepCallbackRequest();
	const [showFollowupRestart, setShowFollowupRestart] = useState(true);
	const [showActivityGroup, setShowActivityGroup] = useState(false);
	const [showPhoneNumberPanel, setShowPhoneNumberPanel] = useState(false);
	const [adviceNode, adviceRef] = useState(null);
	const [isContactRequestForMobileVisible, setIsContactRequestForMobileVisible] = useState(false);
	const { advice } = props;

	// We only show the phone numbers that match the advice referral and urgence
	const activePhoneNumbers = Array.isArray(props.callButtonPhoneNumbers) ? props.callButtonPhoneNumbers : [];
	const showPhoneNumbers = settings.callButtonActive && activePhoneNumbers.length >= 1;
	const showSurvey = !advice.startContactRequestProcedure && !advice.startZorgMailRequestProcedure && settings.survey.active && settings.survey.surveyId > 0;
	const showMGnPatientPortal = settings.patientPortal.active && settings.patientPortal.mgnEventlistenerActive && ['U3', 'U4', 'U5'].includes(advice.urgence);
	const handleToggleModal = useToggleModal();

	const conversationHasRequestCallback = conversation.some((c) => c.type === 'requestCallback' || c.type === 'consultationPreparationQuestion');

	const themeContext = useContext(ThemeContext);
	const isMobile = useWindowSize().width < themeContext.breakpoints.medium;
	const drawerContainerRef = useRef(null);

	const isSendEmailOrSmsAdviceActive = settings.sendEmailOrSmsAdviceActive;

	const handleMoreInfo = async (url) => {
		CallPostSessionLogProperties(settings.ApiKey, {
			sessionId: session.id,
			sessionToken: session.token,
			sessionLogProperties: [
				{
					sessionLogPropertyName: 'AdviceClicked',
					sessionLogPropertyValue: true
				}
			]
		});

		window.open(url, '_blank');
	};

	const handleParacetamolLink = async (url) => {
		window.open(url, '_blank');
	};

	const handleCall = async (phoneNumber = settings.phoneNumber) => {
		await CallPostCallButtonClicked(settings.ApiKey, {
			sessionId: session.id,
			sessionToken: session.token,
			phoneNumber: phoneNumber
		});

		window.open(`tel:${phoneNumber}`, '_self');
	};

	const handleRedirectToUrl = () => {
		if (settings.redirectToDoctorPageUrl && settings.redirectToDoctorPageUrl.trim() !== '') window.open(settings.redirectToDoctorPageUrl, '_blank');
	};

	const handleMGnEventlistenerForPdf = () => {
		void advicePdfEventlistenerHelper(settings, session);
		void handleToggleModal();
	};

	const handleSurveyAction = (answer) => {
		setShowFollowupRestart(false);
		props.handleActivityResponse({ ...props.answer, surveyAnswerId: answer.answerId, surveyAnswerText: answer.answerText });
	};

	const focusLastActivity = useCallback(
		(node) => {
			if (node && props.isLastActivity && props.modalOpen) {
				node.focus();
			}
		},
		[props.isLastActivity, props.modalOpen]
	);

	useEffect(() => {
		// Start the timer after 4 seconds
		const timeoutId = setTimeout(() => {
			setShowActivityGroup(true);
		}, 4000);

		if (showPhoneNumbers) {
			CallPostSessionLogProperties(settings.ApiKey, {
				sessionId: session.id,
				sessionToken: session.token,
				sessionLogProperties: [
					{
						sessionLogPropertyName: 'CallButtonShown',
						sessionLogPropertyValue: true
					}
				]
			});
		}

		return () => clearTimeout(timeoutId);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useLayoutEffect(() => {
		if (adviceNode && refs.widget.current) {
			const widget = refs.widget.current;

			const handleScroll = () => {
				const adviceRect = adviceNode.getBoundingClientRect();
				// Show if bottom of advice is above the bottom of the screen
				// Small increase of window.innerHeigt to also show contactRequest if the bottom of advice is just below the bottom of the screen.
				// Otherwise users may not know they have to scroll down to have the contactRequest pop up
				const showContactRequest = adviceRect.bottom < window.innerHeight * 1.1;
				// If bottom of advice activityBubble is below the the bottom of the screen, hide contactrequest
				const hideContactRequest = adviceRect.bottom > window.innerHeight;

				if (hideContactRequest && !showContactRequest) {
					setIsContactRequestForMobileVisible(false);
				} else {
					setIsContactRequestForMobileVisible(true);
				}
			};
			// Cleanup: Remove the scroll event listener when conversationHasRequestCallback
			if (conversationHasRequestCallback) {
				setIsContactRequestForMobileVisible(false);
				return () => {
					widget.removeEventListener('scroll', handleScroll);
				};
			}
			// Initial check
			handleScroll();
			// Attach the scroll event listener
			widget.addEventListener('scroll', handleScroll);
			// Cleanup: Remove the scroll event listener when the component unmounts
			return () => {
				widget.removeEventListener('scroll', handleScroll);
			};
		}
	}, [refs.widget, adviceNode, conversationHasRequestCallback]);

	const handleContactRequestProcedure = async () => {
		CallPostSessionLogProperties(settings.ApiKey, {
			sessionId: session.id,
			sessionToken: session.token,
			sessionLogProperties: [{ sessionLogPropertyName: 'StartCallbackProcedureClicked', sessionLogPropertyValue: true }]
		});

		await handleRequestCallback();
	};

	const handleZorgMailRequestProcedure = async () => {
		CallPostSessionLogProperties(settings.ApiKey, {
			sessionId: session.id,
			sessionToken: session.token,
			sessionLogProperties: [{ sessionLogPropertyName: 'StartZorgMailProcedureClicked', sessionLogPropertyValue: true }]
		});

		await handleRequestCallback();
	};

	const handleFhirRequestProcedure = async () => {
		await handleRequestCallback();
	};

	const handleRequestCallback = async () => {
		const nextStep = await handleNextStepCallbackRequest(advice);
		dispatch({
			type: 'conversation/addStep',
			step: nextStep
		});

		if (nextStep.type === 'consultationPreparation') {
			void props.handleActivityResponse({ ...props.answer, isConsultationPreparationStarted: true });
		} else if (nextStep.type === 'callbackRequestScheduler') {
			void props.handleActivityResponse({ ...props.answer, isRequestCallbackStarted: true });
		} else {
			void props.handleActivityResponse({ ...props.answer });
		}
	};

	const handleActivityEndActions = async (answer) => {
		switch (answer.message) {
			case 'feedback':
				await props.handleActivityResponse({ ...props.answer, isFeedbackStarted: true });
				break;
			// no default
		}
	};

	const handleOnReleaseDrawer = (event, open) => {
		// drawer can be closed by dragging it down, so for consistent functionality this must be added
		if (!open && showPhoneNumberPanel) {
			setShowPhoneNumberPanel(false);
		}
	};

	useEffect(() => {
		if (showActivityGroup) {
			settings.target.dispatchEvent(
				new CustomEvent('minddWidgetAdviceShown', {
					bubbles: true,
					detail: { adviceId: advice.id }
				})
			);

			if (typeof settings.onAdviceShown === 'function') {
				settings.onAdviceShown.call(null, advice.id);
			}
		}
	}, [showActivityGroup, advice.id, settings.target, settings.onAdviceShown]);

	return (
		<>
			{showActivityGroup ? (
				<>
					<S.ActivityGroup>
						<SA.ActivityBubble ref={adviceRef}>
							{/* #1 General Advice */}
							<S.Icon>
								<S.IconLabel>
									<S.IconBackground></S.IconBackground>
									<SAT.HeaderIcon icon={faBell} />
								</S.IconLabel>
								<SA.BubbleTitle ref={focusLastActivity} tabIndex="-1">
									{renderMarkdown(advice.title)}
								</SA.BubbleTitle>
							</S.Icon>
							<S.ActivityBubbleHeader>
								{settings.profileImageUrl && (
									<S.ProfileImageWrapper>
										<S.ProfileImage profileImage={settings.profileImageUrl}></S.ProfileImage>
									</S.ProfileImageWrapper>
								)}

								<S.FullWidthContainer>
									<SA.LineDevider hasAdvice={true} />
								</S.FullWidthContainer>

								{advice.adviceIntro && <p>{renderMarkdown(advice.adviceIntro)}</p>}
							</S.ActivityBubbleHeader>

							{/* #2 aanvullend advies*/}
							{advice.additionalAdviceChannel && advice.additionalAdviceChannel !== '' && <>{renderMarkdown(advice.additionalAdviceChannel)}</>}

							{/* #3 Ons advies */}
							{(advice.characteristic || advice.showParacetamolLink || advice.linkUrl) && (
								<>
									{advice.characteristic && (
										<>
											<div style={{ margin: '15px 0' }}></div>
											<ExpandableDiv>
												<SA.BubbleSubTitle>{getLabel('AdviceOnsAdvies', settings.applicationTexts)}</SA.BubbleSubTitle>
												<p className="truncateText">{renderMarkdown(advice.characteristic)}</p>
												{advice.linkUrl && (
													<React.Fragment>
														<AwesomeButton
															onClick={() => handleMoreInfo(advice.linkUrl)}
															icon={faExternalLink}
															position="after"
															variant="link"
														>
															{getLabel('AdviceMeerInformatiePrefix', settings.applicationTexts)} {advice.linkTitle}
														</AwesomeButton>
													</React.Fragment>
												)}
											</ExpandableDiv>
										</>
									)}

									{advice.showParacetamolLink && advice.paracetamolAdvice && (
										<S.ParacetamolAdviceBlock>
											<div style={{ margin: '15px 0' }}></div>
											<ExpandableDiv>
												<SA.BubbleSubTitle>{getLabel('AdviceParacetamolAdvies', settings.applicationTexts)}</SA.BubbleSubTitle>
												<div className="truncateText">{renderMarkdown(advice.paracetamolAdvice)}</div>
												{advice.paracetamolAdviceLinkUrl && (
													<AwesomeButton
														onClick={() => handleParacetamolLink(advice.paracetamolAdviceLinkUrl)}
														icon={faExternalLink}
														position="after"
														variant="link"
													>
														{getLabel('AdviceMeerInformatiePrefix', settings.applicationTexts)} {advice.paracetamolAdviceLinkTitle}
													</AwesomeButton>
												)}
											</ExpandableDiv>
										</S.ParacetamolAdviceBlock>
									)}
								</>
							)}

							{/* 'Gespreksverslag downloaden' */}
							{!settings.downloadConversationDisabled && <DownloadButton />}

							{/* #4 Procedures */}
							{settings.askForZipCode && (
								<>
									<SA.LineDevider hasAdvice={true}></SA.LineDevider>
									<SA.BubbleSubTitle>{getLabel('postcodeRequestLabel', settings.applicationTexts)}</SA.BubbleSubTitle>
									<ProcedurePostcodeRequest setBackButtonStatus={props.setBackButtonStatus} />
								</>
							)}

							{advice.startRedirectToDoctorPageProcedure && (
								<>
									<SA.LineDevider hasAdvice={true}></SA.LineDevider>
									<SA.BubbleSubTitle>{getLabel('RedirectToDoctorPageProcedureIntro', settings.applicationTexts)}</SA.BubbleSubTitle>
									<SA.ActivityActions>
										<Button onClick={handleRedirectToUrl}>
											{getLabel('RedirectToDoctorPageProcedureButton', settings.applicationTexts)}
										</Button>
									</SA.ActivityActions>
								</>
							)}

							{isSendEmailOrSmsAdviceActive && (
								<>
									<S.FullWidthContainer>
										<SA.LineDevider hasAdvice={true} />
									</S.FullWidthContainer>
									<SA.BubbleSubTitle>{getLabel('sendAdviceEmailSmsLabel', settings.applicationTexts, true)}</SA.BubbleSubTitle>
									<ProcedureSendEmailOrSmsAdvice setBackButtonStatus={props.setBackButtonStatus} />
								</>
							)}

							{(advice.startContactRequestProcedure || advice.startZorgMailRequestProcedure || advice.startFhirProcedure || showPhoneNumbers) && (
								<>
									{!isMobile && (
										<S.FullWidthContainer>
											<SA.LineDevider hasAdvice={true} />
										</S.FullWidthContainer>
									)}

									<ContactRequestContainer hasRequestCallback={conversationHasRequestCallback} isVisible={isContactRequestForMobileVisible}>
										{advice.startContactRequestProcedure && (
											<div>{renderMarkdown(settings.contactOptions.labels.callbackRequestIntro)}</div>
										)}

										{advice.startZorgMailRequestProcedure && !advice.startContactRequestProcedure && (
											<div>{renderMarkdown(settings.contactOptions.labels.zorgMailRequestIntro)}</div>
										)}

										{showPhoneNumbers && !advice.startContactRequestProcedure && (
											<div>{renderMarkdown(settings.contactOptions.labels.directCallOptionIntro)}</div>
										)}

										<S.ActivityActionsContactButtons>
											{advice.startContactRequestProcedure && (
												<IconButton
													disabled={conversationHasRequestCallback}
													state={conversationHasRequestCallback}
													icon={faPhone}
													position={'before'}
													primary={true}
													onClick={handleContactRequestProcedure}
												>
													{renderMarkdown(settings.contactOptions.labels.callbackRequestButton)}
												</IconButton>
											)}

											{/* We use the IconButton here, because other Buttons use other styling */}
											{advice.startZorgMailRequestProcedure && !advice.startContactRequestProcedure && (
												<IconButton
													state={conversationHasRequestCallback}
													disabled={conversationHasRequestCallback}
													primary={true}
													onClick={handleZorgMailRequestProcedure}
												>
													{renderMarkdown(settings.contactOptions.labels.zorgMailRequestButton)}
												</IconButton>
											)}

											{advice.startFhirProcedure && !advice.startContactRequestProcedure && (
												<IconButton
													state={conversationHasRequestCallback}
													disabled={conversationHasRequestCallback}
													primary={true}
													onClick={handleFhirRequestProcedure}
												>
													{renderMarkdown(settings.contactOptions.labels.fhirRequestButton)}
												</IconButton>
											)}

											{showPhoneNumbers && (
												<S.ShowPhoneNumbersButton
													onClick={() => setShowPhoneNumberPanel(!showPhoneNumberPanel)}
													icon={faAngleDown}
													position={'after'}
													rotateIcon={showPhoneNumberPanel}
													primary={!advice.startContactRequestProcedure && !advice.startZorgMailRequestProcedure}
												>
													{renderMarkdown(settings.contactOptions.labels.directCallButtonText)}
												</S.ShowPhoneNumbersButton>
											)}
										</S.ActivityActionsContactButtons>
									</ContactRequestContainer>
								</>
							)}

							{/* mobile */}
							{isMobile && showPhoneNumbers && (
								<WidgetDrawer
									ref={drawerContainerRef}
									isOpen={showPhoneNumberPanel}
									onRelease={handleOnReleaseDrawer}
									container={drawerContainerRef.current}
								>
									<SD.DrawerHeader>
										<SD.DrawerBubbleSubTitle>{getLabel('phoneNumbersLabel', settings.applicationTexts)}</SD.DrawerBubbleSubTitle>
										<SD.CloseDrawerButton onClick={() => setShowPhoneNumberPanel(false)} variant="link">
											{getLabel('AdviceButtonLabelClose', settings.applicationTexts)}
										</SD.CloseDrawerButton>
									</SD.DrawerHeader>
									<SD.ScrollableContentWrapper data-vaul-no-drag>
										<PhoneNumberList handleCall={handleCall} activePhoneNumbers={activePhoneNumbers} />
									</SD.ScrollableContentWrapper>
								</WidgetDrawer>
							)}

							{/* desktop */}
							{!isMobile && showPhoneNumbers && showPhoneNumberPanel && (
								<S.PhoneNumberPanelContainer>
									<SA.LineDevider hasAdvice={true} />
									<S.PhoneNumberPanel>
										<S.PhoneNumberPanelLabel>{getLabel('phoneNumbersLabel', settings.applicationTexts)}</S.PhoneNumberPanelLabel>
										<PhoneNumberList handleCall={handleCall} activePhoneNumbers={activePhoneNumbers} />
									</S.PhoneNumberPanel>
									<SA.LineDevider hasAdvice={true} />
								</S.PhoneNumberPanelContainer>
							)}

							{advice.adviceOutro && (
								<>
									{(showPhoneNumberPanel && !isMobile) || (
										<S.FullWidthContainer>
											<SA.LineDevider hasAdvice={true} />
										</S.FullWidthContainer>
									)}
									<p>{renderMarkdown(advice.adviceOutro)}</p>
								</>
							)}

							{showSurvey && (
								<>
									{(!advice.adviceOutro && showPhoneNumberPanel && !isMobile) || (
										<S.FullWidthContainer>
											<SA.LineDevider hasAdvice={true} />
										</S.FullWidthContainer>
									)}
									<SurveyIntro disabled={props.disabled} handleActivityResponse={handleSurveyAction} />
								</>
							)}

							{/* Procedure: Patientportal MGn eventListener */}
							{showMGnPatientPortal && (
								<>
									<SA.LineDevider hasAdvice={true}></SA.LineDevider>
									<SA.BubbleSubTitle>{renderMarkdown(settings.patientPortal.labels.mgnButtonIntro || '')}</SA.BubbleSubTitle>
									<SA.ActivityActions>
										<Button onClick={handleMGnEventlistenerForPdf}>{renderMarkdown(settings.patientPortal.labels.mgnButton || '')}</Button>
									</SA.ActivityActions>
								</>
							)}

							{/* #5 follow up feedback/opnieuw */}
							{showFollowupRestart && (
								<>
									{(!advice.adviceOutro && !showSurvey && showPhoneNumberPanel && !isMobile) || (
										<S.FullWidthContainer>
											<SA.LineDevider hasAdvice={true} />
										</S.FullWidthContainer>
									)}

									<EndButtons
										showFeedbackAction={!(settings.survey.active && settings.survey.surveyId > 0)}
										handleActivityResponse={handleActivityEndActions}
									/>
								</>
							)}
						</SA.ActivityBubble>
					</S.ActivityGroup>
				</>
			) : (
				<MinddLogoLoader />
			)}
		</>
	);
};

export default ActivityAdvice;
